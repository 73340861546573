import type { MouseEvent } from 'react';
import styled from 'styled-components';
import type { CategoryGridItem as CategoryGridItemSchema } from '@amplience/content-types/typings/c-category-grid-item';
import { ImagePartial } from '@storefront/lib-global/partials/image';
import { LinkPartial } from '@storefront/lib-global/partials/link';
import { TypographyStyles, colours, maxWidthPartial, media, spacing, zBase } from '@storefront/lib-global/stylings';
import { sendSelectContentEvent } from '@storefront/lib-global/utils/gtm/events/select_content/sendSelectContentEvent';
import { getLocalizedValue } from '@storefront/lib-global/utils/transformers';

const S = {
  Container: styled.ul`
    ${maxWidthPartial({ withPaddingXL: true })}
    column-gap: ${spacing.XXXS};
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    page-break-inside: avoid;
    position: relative;
    row-gap: 20px;
    z-index: ${zBase};

    @media ${media.greaterThan('lg')} {
      display: flex;
      gap: ${spacing.M};
      justify-content: center;
    }
  `,

  Card: styled.li`
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: ${spacing.XXS};
    position: relative;

    a {
      text-decoration: none;

      > *:not(:last-child) {
        margin-bottom: ${spacing.XXS};
      }

      &:focus-visible {
        outline: solid 1px ${colours.BLACK};
      }
    }

    @media ${media.greaterThan('lg')} {
      gap: ${spacing.XS};
      max-width: 300px;

      a > *:not(:last-child) {
        margin-bottom: ${spacing.XS};
      }
    }
  `,

  ImageContainer: styled.div`
    aspect-ratio: 1/1;
    overflow: hidden;
    position: relative;

    & span {
      transition: transform 0.3s ease-in-out;

      :hover,
      :focus {
        transform: scale(1.05);
      }

      @media (prefers-reduced-motion) {
        transition: none;
      }
    }
  `,

  Text: styled.h3<{ $textColor?: string }>`
    color: ${({ $textColor }) => $textColor || colours.BLACK};
    ${TypographyStyles.Headings.H4SemiBold}
    margin: 0;
    overflow-wrap: anywhere;
    text-align: center;
    text-transform: uppercase;
  `,
};

export type CategoryGridItemProps = CategoryGridItemSchema;

export function CategoryGridItem({ categoryItems }: CategoryGridItemProps) {
  const handleClick = (heading: string) => (event: MouseEvent<HTMLAnchorElement>) => {
    const element = event.currentTarget as HTMLAnchorElement;

    sendSelectContentEvent({
      component_header: heading.trim(),
      component_type: 'Category Grid Item',
      link_url: element.href,
      link_text: element.textContent ?? '',
    });
  };

  return (
    <S.Container>
      {categoryItems?.map((item, i) => (
        <S.Card key={i}>
          <LinkPartial {...item.link} onClick={handleClick(getLocalizedValue(item.textHeading ?? ''))}>
            <>
              {item.image?.image?.name && (
                <S.ImageContainer>
                  <ImagePartial
                    layout="fill"
                    src={item.image?.image.name}
                    altText={item.image?.altText}
                    assetType={item.image?.assetType}
                    defaultHost={item.image.image?.defaultHost}
                    useOriginalSize={item.image.useOriginalSize}
                    aria-hidden
                  />
                </S.ImageContainer>
              )}
              <S.Text $textColor={item.textColor}>{getLocalizedValue(item.textHeading)}</S.Text>
            </>
          </LinkPartial>
        </S.Card>
      ))}
    </S.Container>
  );
}
